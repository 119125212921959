<script setup lang="ts">
import type { PaymentCardModel } from '~/types/bookingPayment'

interface Props {
  isLoadingAmount?: boolean
}

defineProps<Props>()

const cardNumberModel = defineModel<PaymentCardModel['cardNumber'] | null>('card', {
  required: true,
  default: null,
})
const cardEl = ref()

const expirationDateMonthModel = defineModel<PaymentCardModel['expirationDateMonth'] | null>('month', {
  required: true,
  default: null,
})
const monthEl = ref()

const expirationDateYearModel = defineModel<PaymentCardModel['expirationDateYear'] | null>('year', {
  required: true,
  default: null,
})
const yearEl = ref()

const ccvModel = defineModel<PaymentCardModel['ccv'] | null>('ccv', {
  required: true,
  default: null,
})
const ccvEl = ref()

const { t } = useI18n()

const onCardNumberChange = (value: string | null | number) => {
  if (!isDefined(value)) {
    return
  }

  cardEl.value.$refs.input.setCustomValidity('')

  if (!validateCardNumber(value.toString())) {
    cardEl.value.$refs.input.setCustomValidity(t('components.app_booking_payment_form_cb.error_invalid_card_number'))
    return
  }
}

const onUpdateExpirationDateMonth = (value: string | number | null) => {
  const TWO_DIGIT_MONTH_REGEX = /^(0[1-9]|1[0-2])$/
  if (!isDefined(value) || !TWO_DIGIT_MONTH_REGEX.test(value.toString())) {
    return
  }

  yearEl.value.$refs.input.focus()
  validateExpirationDateFields()
}

const onUpdateExpirationDateYear = (value: number | null | string) => {
  const TWO_DIGIT_YEAR_REGEX = /^(0[0-9]|[1-9][0-9])$/
  if (!isDefined(value) || !TWO_DIGIT_YEAR_REGEX.test(value.toString())) {
    return
  }

  ccvEl.value.$refs.input.focus()
  validateExpirationDateFields()
}

const validateExpirationDateFields = async () => {
  await nextTick() // needed to ensure updated refs
  const month = expirationDateMonthModel.value
  const year = expirationDateYearModel.value
  yearEl.value.$refs.input.setCustomValidity('')
  monthEl.value.$refs.input.setCustomValidity('')

  if (!isDefined(month) || !isDefined(year)) {
    // we only need custom validation if both field have a value, if one of the field is missing the 'required' attributes will take care of it.
    return
  }

  const currentYear = new Date().getFullYear() % 100 // only two digit
  if (currentYear > +year) {
    yearEl.value.$refs.input.setCustomValidity(t('components.app_booking_payment_form_cb.error_invalid_expiration_date'))
    return
  }

  const currentMonth = new Date().getMonth() + 1 // Align with input where month is 1-indexed
  if (currentYear === +year && currentMonth > +month) {
    monthEl.value.$refs.input.setCustomValidity(t('components.app_booking_payment_form_cb.error_invalid_expiration_date'))
    return
  }
}
</script>

<template>
  <div class="cbForm flex flex-col text-sm">
    <div
      v-if="$slots.amount"
      class="border-b border-gray-200 p-6"
    >
      <YscLoader
        v-if="isLoadingAmount"
        class="mx-auto h-6 w-6 !block"
      />
      <i18n-t
        v-else
        keypath="components.app_booking_payment_form_cb.amount_info"
        tag="p"
        class=" text-sm"
        scope="global"
      >
        <template #amount>
          <span class="font-semibold"><slot name="amount" /></span>
        </template>
      </i18n-t>
    </div>
    <div class="rounded-b-sm bg-gray-100 p-6 pl-6">
      <div class="flex max-w-md flex-col gap-4 sentry-mask">
        <YscInputKeyboard
          id="cb_form_card_number"
          ref="cardEl"
          v-model="cardNumberModel"
          name="card_number"
          :placeholder="$t('components.app_booking_payment_form_cb.card_number_label')"
          pattern="\d+"
          type="text"
          inputmode="numeric"
          autocomplete="cc-number"
          full-width
          required
          @update:model-value="onCardNumberChange"
        />
        <div class="flex flex-col gap-4 lg:flex-row">
          <div class="flex flex-row flex-1 space-x-2 items-center">
            <YscInputKeyboard
              id="cb_form_expiration_date_month"
              ref="monthEl"
              v-model="expirationDateMonthModel"
              name="expiration_date_month"
              autocomplete="cc-exp-month"
              placeholder="MM"
              type="text"
              inputmode="numeric"
              pattern="^(0[1-9]|1[0-2])$"
              minlength="2"
              maxlength="2"
              required
              full-width
              @update:model-value="onUpdateExpirationDateMonth"
            />
            <span class="text-lg">/</span>
            <YscInputKeyboard
              id="cb_form_expiration_date_year"
              ref="yearEl"
              v-model="expirationDateYearModel"
              name="expiration_date_year"
              autocomplete="cc-exp-year"
              placeholder="YY"
              type="text"
              inputmode="numeric"
              pattern="^(0[0-9]|[1-9][0-9])$"
              minlength="2"
              maxlength="2"
              required
              full-width
              @update:model-value="onUpdateExpirationDateYear"
              @blur="validateExpirationDateFields"
            />
          </div>
          <YscInputKeyboard
            id="cb_form_ccv"
            ref="ccvEl"
            v-model="ccvModel"
            name="ccv"
            :placeholder="$t('components.app_booking_payment_form_cb.ccv_label')"
            pattern="^[0-9]{3,4}$"
            type="text"
            autocomplete="cc-csc"
            inputmode="numeric"
            full-width
            required
          />
        </div>
        <p class="flex flex-row items-center text-xs text-gray-500">
          <ysc-icons-lock class="mr-1 w-3" />Payment secured and encrypted by
          <img
            alt="Mangopay"
            class="ml-1 h-2"
            src="@/assets/img/payments/powered-by-mangopay.png"
          >
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cbForm :deep(input) {
  @apply text-sm placeholder:text-sm placeholder:text-black;
}
</style>
