import type { SinglePaymentResponse } from '@yescapa-dev/ysc-api-js/legacy'
import type { CardPaymentType, PaymentCardModel } from '~/types/bookingPayment'
import type { AllNullable } from '~/types/commons'
import { CardRegistrationError } from '~/utils/error/CardRegistrationError'
import { YSC_API_PAYMENT_ERROR, YSC_CARD_REGISTRATION_ERROR } from '~/utils/error/YscErrorClasses'

// doc: https://docs.mangopay.com/api-reference/card-registrations/view-card-registration#responses
type MangopayCardRegistration = {
  Id: string
  Tag: string | null
  CreationDate: string
  UserId: string
  AccessKey: string
  PreregistrationData: string
  RegistrationData: string
  CardId: string
  CardType: 'CB_VISA_MASTERCARD' | 'AMEX' | 'MAESTRO' | 'BCMC'
  CardRegistrationURL: string
  ResultCode: string // https://docs.mangopay.com/errors/codes
  ResultMessage: string
  Currency: string
  Status: 'CREATED' | 'VALIDATED' | 'ERROR'
}

// card payment can be frictionless or 3DS2
// for 1x or 2x payment, process is the same
export const useExecutePaymentCard = ({ idBooking }: { idBooking: number | string }) => {
  const { $api } = useYscApi()
  const { $errorManager } = useErrorManager()
  const { withErrorManagerHandling } = useWithErrorManagerHandling()
  const { $i18n: { locale } } = useNuxtApp()

  const registerCard = async ({
    payment_type,
    cardData,
  }: {
    payment_type: CardPaymentType
    cardData: PaymentCardModel
  }) => {
    const {
      card_registration: cardRegistration,
      mangopay_client_id: mangoPayClientId,
      mangopay_base_url: mangoPayBaseUrl,
    } = await $api.requests.getSinglePayment(idBooking, { payment_type })

    if (!cardRegistration.card_reg_url) {
      throw new Error('Api returned null card_reg_url')
    }

    const registrationData = await $fetch<string>(cardRegistration.card_reg_url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        data: cardRegistration.preregistration_data || '',
        accessKeyRef: cardRegistration.access_key || '',
        cardExpirationDate: `${cardData.expirationDateMonth}${cardData.expirationDateYear}`,
        cardCvx: cardData.ccv,
        cardNumber: cardData.cardNumber,
      }),
      parseResponse: (txt: string) => txt, // response is raw text
    })

    // Something wrong, no data came back from Payline
    if (registrationData.startsWith('errorCode=')) {
      throw $errorManager({
        e: new CardRegistrationError('Token processing error', {
          code: registrationData.replace('errorCode=', ''),
          errorType: 'token',
        }),
        name: YSC_CARD_REGISTRATION_ERROR,
      })
    }

    const mangoPayCard = await $fetch<MangopayCardRegistration>(
      `${mangoPayBaseUrl}/v2.01/${mangoPayClientId}/CardRegistrations/${cardRegistration.mangopay_id}`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          Id: mangoPayClientId,
          RegistrationData: registrationData,
        }),
      },
    )

    if (mangoPayCard.ResultCode !== '000000') {
      throw $errorManager({
        e: new CardRegistrationError('Card registration error', {
          code: mangoPayCard.ResultCode,
          errorType: 'card',
        }),
        name: YSC_CARD_REGISTRATION_ERROR,
      })
    }

    return { cardRegistration, mangoPayCard }
  }

  const confirmPayment = async ({
    cardRegistration,
    mangoPayCard,
    paymentRedirectUrl,
  }: {
    cardRegistration: SinglePaymentResponse['card_registration']
    mangoPayCard: MangopayCardRegistration
    paymentRedirectUrl: MaybeRefOrGetter<string>
  }) => {
    const data = await withErrorManagerHandling(
      () => $api.requests.confirmPayment(idBooking, {
        pre_registration: cardRegistration.id,
        card_id: mangoPayCard.CardId,
        registration_data: mangoPayCard.RegistrationData,
        accept_header: 'text/html, application/xhtml+xml, application/xml;q=0.9, */*;q=0.8',
        java_enabled: false,
        javascript_enabled: true,
        language: locale.value,
        color_depth: screen.colorDepth,
        screen_height: screen.height,
        screen_width: screen.width,
        timezone_offset: new Date().getTimezoneOffset(),
        user_agent: window.navigator.userAgent,
        redirect_url: toValue(paymentRedirectUrl),
      }),
      YSC_API_PAYMENT_ERROR,
      true,
    )

    if ((isDefined(data.error_pay_in_code) && data.error_pay_in_code !== '000000')) {
      const e = new Error()
      // @ts-expect-error create a custom error to handle this properly
      e.code = data.error_pay_in_code
      throw e
    }

    return data
  }

  // Main method
  const executePayment = async ({
    payment_type,
    form,
    paymentRedirectUrl,
  }: {
    payment_type: CardPaymentType
    form: AllNullable<PaymentCardModel>
    paymentRedirectUrl: MaybeRefOrGetter<string>
  }) => {
    assertAllNullableTypeIsType(form)
    const {
      cardRegistration,
      mangoPayCard,
    } = await registerCard({ payment_type, cardData: form })

    const confirmation = await confirmPayment({ cardRegistration, mangoPayCard, paymentRedirectUrl })

    if (confirmation.secure_mode_redirect_url) {
      await navigateTo(confirmation.secure_mode_redirect_url, { external: true })
      return { confirmation }
    }

    assertsPropertiesAreDefined(confirmation, ['transaction_id'])

    return { confirmation }
  }

  return { executePayment }
}
