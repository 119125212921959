<script setup lang="ts">
import { BOOKING_PAYMENT_METHODS } from '@yescapa-dev/ysc-api-js/modern'
import type { AvailablePaymentType, PaymentCardModel } from '~/types/bookingPayment'
import type { AllNullable, Currency } from '~/types/commons'
import { YSC_API_BOOKING_GUEST_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  idBooking: number
  priceValue: number | null
  currency: Currency
}

const props = defineProps<Props>()

const valueModel = defineModel<AvailablePaymentType | null>({ required: false, default: null })
const formModel = defineModel<AllNullable<PaymentCardModel>>('form', {
  required: false,
  default: () => ({
    cardNumber: null,
    expirationDateYear: null,
    expirationDateMonth: null,
    ccv: null,
  }),
})

const payment_type = BOOKING_PAYMENT_METHODS.CREDIT_CARD
const { withErrorManagerHandling } = useWithErrorManagerHandling()
const { $api } = useYscApi()
const { status, data, execute } = await useAsyncData(`booking-${props.idBooking}-payment-methods-${payment_type}`, async () => {
  const { payments } = await withErrorManagerHandling(
    async () => $api.requests.getSinglePayment(props.idBooking, { payment_type }),
    YSC_API_BOOKING_GUEST_ERROR,
    false,
  )
  return payments
}, {
  immediate: false,
})

const { isPending } = useIsPending(status)

watch(valueModel, () => {
  if (valueModel.value === payment_type && !isDefined(data.value)) {
    execute()
  }
})
//  Use payments[0].price because the api requests.patchPrices don't use rental credit in price calculation. related to TT-7594
const amount = computed(() => data.value?.[0]?.price ?? props.priceValue)
</script>

<template>
  <AppBookingPaymentPaymentOption
    v-model="valueModel"
    :value="payment_type"
    :input-id="payment_type"
  >
    <template #label>
      {{ $t('pages.checkout.payment.methods.credit_card') }}
    </template>
    <template #logos>
      <AppLogoMasterCard />
      <AppLogoVisa />
    </template>
    <template #expanded>
      <AppPaymentModelCard
        v-model:card="formModel.cardNumber"
        v-model:month="formModel.expirationDateMonth"
        v-model:year="formModel.expirationDateYear"
        v-model:ccv="formModel.ccv"
        :is-loading-amount="isPending"
      >
        <template
          v-if="isDefined(amount)"
          #amount
        >
          {{ $n(amount, props.currency) }}
        </template>
      </AppPaymentModelCard>
    </template>
  </AppBookingPaymentPaymentOption>
</template>
