<script setup lang="ts">
interface Props {
  isValidated?: boolean
  loading?: boolean
}

defineProps<Props>()

const couponCodeModel = defineModel<string>('couponCode')

const emit = defineEmits<{
  submitted: []
}>()
</script>

<template>
  <div class="space-y-2">
    <YscLabel
      for="coupon_code"
      class="text-lg font-semibold"
      required
    >
      {{ $t('data.price.coupon_code.label') }}
    </YscLabel>
    <div
      v-if="isValidated"
      class="fake-input flex items-center justify-between"
    >
      <div>
        <p class="text-sm font-semibold">
          {{ couponCodeModel }}
        </p>
        <p class="text-xs text-gray-500">
          {{ $t('pages.checkout.overview.coupon_applied') }}
        </p>
      </div>
      <YscIconsCheckBold class="h-6 w-6 text-green-700" />
    </div>

    <div
      v-else
      class="relative"
    >
      <form
        id="coupon-form"
        class="fake-input focus-within:ring-2 focus-within:ring-peacock-500 focus-within:ring-opacity-50 focus-within:ring-offset-2 "
        @submit.stop.prevent="emit('submitted')"
      >
        <input
          id="coupon_code"
          v-model="couponCodeModel"
          type="text"
          name="coupon"
          class="w-full appearance-none bg-transparent outline-none "
          :placeholder="$t('pages.checkout.enter-coupon-code')"
          required
        >
      </form>
      <YscButtonSubmit
        form="coupon-form"
        type="submit"
        class="btn-small btn-secondary absolute top-1/2 right-2 -translate-y-1/2 rounded-sm"
        :loading="loading"
      >
        {{ $t('pages.checkout.overview.apply') }}
      </YscButtonSubmit>
    </div>
  </div>
</template>

<style lang="css" scoped>
.fake-input{
  @apply border border-gray-300 px-3 py-4 rounded-sm;
}
</style>
