import type { BOOKING_PAYMENT_METHODS } from '@yescapa-dev/ysc-api-js/modern'
import { YSC_API_PAYMENT_WEB_REDIRECT_ERROR } from '~/utils/error/YscErrorClasses'

// Redirected payment are handled by third party providers
export const useExecutePaymentRedirected = ({ idBooking }: { idBooking: number | string }) => {
  const { $api } = useYscApi()
  const { withErrorManagerHandling } = useWithErrorManagerHandling()

  // main method
  const executePayment = async ({
    payment_type,
    paymentRedirectUrl,
  }: {
    paymentRedirectUrl: MaybeRefOrGetter<string>
    payment_type: BOOKING_PAYMENT_METHODS.PAYPAL | BOOKING_PAYMENT_METHODS.KLARNA
    backUrl: MaybeRefOrGetter<string>
  }) => {
    const { redirect_url } = await withErrorManagerHandling(
      () => $api.requests.getPaymentWebRedirect(idBooking, {
        payment_type,
        redirect_url: toValue(paymentRedirectUrl),
      }),
      YSC_API_PAYMENT_WEB_REDIRECT_ERROR,
      true,
    )

    await navigateTo(redirect_url, { external: true })
  }

  return { executePayment }
}
