import type { BOOKING_PAYMENT_METHODS } from '@yescapa-dev/ysc-api-js/modern'
import type { MbwayForm } from '~/types/bookingPayment'
import type { AllNullable } from '~/types/commons'
import { YSC_API_PAYMENT_ERROR } from '~/utils/error/YscErrorClasses'

// MB Way send a link to your phone in order to validate the payment
export const useExecutePaymentMBWay = ({ idBooking }: { idBooking: number | string }) => {
  const { $api } = useYscApi()
  const { withErrorManagerHandling } = useWithErrorManagerHandling()

  // Main method
  const executePayment = async ({
    payment_type,
    form,
  }: {
    payment_type: BOOKING_PAYMENT_METHODS.MBWAY
    form: AllNullable<MbwayForm>
  }) => {
    assertAllNullableTypeIsType(form, 'phone')
    const { pay_in_id } = await withErrorManagerHandling(
      () => $api.requests.createAsyncPayment(idBooking, {
        payment_type,
        ...form,
      }),
      YSC_API_PAYMENT_ERROR,
      true,
    )

    return { pay_in_id }
  }

  return { executePayment }
}
