import type { BOOKING_PAYMENT_METHODS } from '@yescapa-dev/ysc-api-js/modern'
import type { IdealForm } from '~/types/bookingPayment'
import type { AllNullable } from '~/types/commons'
import { YSC_API_PAYMENT_WEB_REDIRECT_ERROR } from '~/utils/error/YscErrorClasses'

// allow user to pay in 4 times
export const useExecutePaymentIDeal = ({ idBooking }: { idBooking: number | string }) => {
  const { $api } = useYscApi()
  const { withErrorManagerHandling } = useWithErrorManagerHandling()

  // main method
  const executePayment = async ({
    payment_type,
    paymentRedirectUrl,
    form,
  }: {
    paymentRedirectUrl: MaybeRefOrGetter<string>
    payment_type: BOOKING_PAYMENT_METHODS.IDEAL
    form: AllNullable<IdealForm>
  }) => {
    assertAllNullableTypeIsType(form, 'bic')
    const { redirect_url } = await withErrorManagerHandling(
      () => $api.requests.getPaymentWebRedirect(idBooking, {
        payment_type,
        redirect_url: toValue(paymentRedirectUrl),
        bic: form.bic,
      }),
      YSC_API_PAYMENT_WEB_REDIRECT_ERROR,
      true,
    )

    await navigateTo(redirect_url, { external: true })
  }

  return { executePayment }
}
