import type { PaymentWebRedirectResponse } from '@yescapa-dev/ysc-api-js/legacy'
import type { BOOKING_PAYMENT_METHODS } from '@yescapa-dev/ysc-api-js/modern'
import { YSC_API_PAYMENT_WEB_REDIRECT_ERROR } from '~/utils/error/YscErrorClasses'

// allow user to pay in 4 times
export const useExecutePaymentCB4x = ({ idBooking }: { idBooking: number | string }) => {
  const { $api } = useYscApi()
  const { withErrorManagerHandling } = useWithErrorManagerHandling()

  const redirectPayment = ({ redirect_url, cb4x_data }: PaymentWebRedirectResponse) => {
    const formEl: HTMLFormElement = document.createElement('form')
    formEl.method = 'POST'
    formEl.action = redirect_url
    Object.entries(cb4x_data ?? {}).forEach(([key, value]) => {
      const el = document.createElement('input')
      el.type = 'hidden'
      el.name = key
      el.value = String(value)
      formEl.append(el)
    })
    document.body.append(formEl)
    formEl.submit()
  }

  // main method
  const executePayment = async ({
    payment_type,
    paymentRedirectUrl,
    backUrl,
  }: {
    paymentRedirectUrl: MaybeRefOrGetter<string>
    payment_type: BOOKING_PAYMENT_METHODS.CREDIT_CARD_4x
    backUrl: MaybeRefOrGetter<string>
  }) => {
    const { redirect_url, cb4x_data } = await withErrorManagerHandling(
      () => $api.requests.getPaymentWebRedirect(idBooking, {
        payment_type,
        redirect_url: toValue(paymentRedirectUrl),
        back_url: toValue(backUrl),
      }),
      YSC_API_PAYMENT_WEB_REDIRECT_ERROR,
      true,
    )

    redirectPayment({ redirect_url, cb4x_data })
  }

  return { executePayment }
}
